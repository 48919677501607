import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Badge, Button } from 'react-bootstrap';
import { IoAddCircleOutline, IoBagAddOutline, IoBagHandleOutline, IoStar, IoStarOutline } from 'react-icons/io5';
import Slider from 'react-slick';
import './Showcase.css'
import { Link } from 'react-router-dom';
import banner1 from '../../assets/images/showcase/banner1.png'
import banner2 from '../../assets/images/showcase/banner2.png'
import banner3 from '../../assets/images/showcase/banner3.png'
function Showcase() {


    return (
        <section className='py-2 wow fadeInDown'>
            <Container>
                <Row className='g-0'>
                    <Col sm={12} lg={8} md={12} className='mb-1'>
                        <Link to="/collection/Oversized%20Anime%20Printed%20T-shirts">
                            <Card className='me-1 bg-transparent card-hover h-100 border-0'>
                                <div className="card-img-container  h-100" >
                                    <Card.Img src={banner1} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100'/>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                    <Col sm={12} lg={4} md={12} className='mb-1'>
                        <Row className='g-1'>
                            <Col sm={12} lg={12} md={12}>
                                <Link to="/collection/Oversized%20Solid%20T-shirts">
                                    <Card className='me-1 bg-transparent card-hover border-0'>
                                        <div className="card-img-container">
                                        <Card.Img src={banner2} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100'/>
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                            <Col sm={12} lg={12} md={12}>
                                <Link to="/collection/Plain%20T-shirts">
                                    <Card className='me-1 bg-transparent card-hover border-0'>
                                        <div className="card-img-container">
                                        <Card.Img src={banner3} alt="Gender Image" variant='top' style={{ Height: '40vh !important' }} className='h-100'/>
                                        </div>
                                    </Card>
                                </Link>
                            </Col >
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Showcase
