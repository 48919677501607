import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Form, InputGroup, Row, Col } from 'react-bootstrap';
import ProductCard from '../../components/ProductCard/ProductCard';
import { IoSearchOutline } from 'react-icons/io5';
import { debounce } from 'lodash'; // Import lodash debounce

function Search() {
    const baseUrl = 'https://stylespotlightco.in/';
    const [products, setProducts] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        if (searchInput !== '') {
            // Debounced search request
            const debouncedSearch = debounce(() => {
                getSearchProducts(searchInput);
            }, 500); // Wait 500ms before calling API

            debouncedSearch();
            return () => {
                debouncedSearch.cancel(); // Cleanup
            };
        }
    }, [searchInput]);

    const getSearchProducts = async (searchValue) => {
        try {
            const response = await axios.get(`https://stylespotlightco.in/api/client_api/search/?searchValue=${searchValue}`);
            setProducts(response.data); // Update state with fetched products
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        setSearchInput(e.target.value); // Update searchInput state with input value
    };

    return (
        <div>
            <Container fluid>
                <section className="sectionView">
                    <h2 className="title text-white text-center py-5" style={{ fontSize: '2rem' }}>
                        Search our wide range of products...
                    </h2>
                    <Form onSubmit={(e) => e.preventDefault()} className='align-items-center d-flex justify-content-center'>
                        <Form.Floating className="mb-3 w-75">
                            <Form.Control
                                type="text"
                                id="formOrderId"
                                placeholder="Search..."
                                value={searchInput}
                                onChange={handleInputChange}
                                required
                                className="rounded-0"
                            />
                            <Form.Label htmlFor="formOrderId">Search...</Form.Label>
                        </Form.Floating>
                    </Form>
                    <Row className="g-0">
                        {products.length > 0 &&
                            <>
                                <h2 className="title text-white text-center py-5" style={{ fontSize: '1rem' }}>
                                    Found {products.length} product(s)
                                </h2>
                                {products.map((item, index) => (
                                    <Col lg={3} md={4} sm={6} xs={6} key={index}>
                                        <ProductCard item={item} baseUrl={baseUrl} />
                                    </Col>
                                ))}
                            </>
                        }
                    </Row>
                </section>
            </Container>
        </div>
    );
}

export default Search;
