// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import SecureLS from "secure-ls";
import axios from "axios";

const firebaseConfig = {
    apiKey: "AIzaSyCqySMW_cYg_D98uj6WGIV0ig6oqYXbhr8",
    authDomain: "stylespotlightco-7e68c.firebaseapp.com",
    projectId: "stylespotlightco-7e68c",
    storageBucket: "stylespotlightco-7e68c.appspot.com",
    messagingSenderId: "925073402952",
    appId: "1:925073402952:web:ee208b06225690da483c7d",
    measurementId: "G-11ECKY0MJG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
const ls = new SecureLS({ encodingType: 'aes' });


// Export the messaging instance and the generateToken function
export const generateToken = async () => {
    const email = ls.get('userEmail');
    const name = ls.get('userName');

    if (email && name) {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey:"BJ_c6qDsFfeBRB8wJtvFrchv_5y2pWaEnEssYUBq2cXG2DOgV7itZPZYccCJ2y1AnWi4RHFqpnjGY9amvC0yxqk"
            });
            addtoTokens(email, token)
        }
    }
};

const addtoTokens = async (username, token) => {
    const isregistered = ls.get('tokenregistered');
    if (isregistered == 'false' || isregistered == '') {
        try {
            const response = await axios.get('https://stylespotlightco.in/api/client_api/notifications/registertokens.php', {
                params: {
                    token: token,
                    email: username
                }
            });
            if (response.data.success) {
                ls.set('tokenregistered', 'true');
            } else {
                ls.set('tokenregistered', 'false');
            }
        } catch (error) {
            console.error('Error adding to cart:', error);
            return false; // Indicate failure
        }
    }
};




export { messaging }; // Add this line to export messaging
