import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Badge, Button } from 'react-bootstrap';
import { IoAddCircleOutline, IoBagAddOutline, IoBagHandleOutline, IoStar, IoStarOutline } from 'react-icons/io5';
import Slider from 'react-slick';
import './Banner.css'
import { Link } from 'react-router-dom';
import promo1 from '../../assets/images/promotions/promo1.png'
function Banner() {


    return (
        <section>
            <Card.Img src={promo1} alt="promo Image"className='h-100' />
        </section>
    )
}

export default Banner
