import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Image, Button, ButtonGroup, ButtonToolbar, Tabs, Tab, Card, Form, ListGroup, Accordion, ProgressBar } from 'react-bootstrap';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import RecentlyAdded from '../../components/Products/RecentlyAdded';
import DOMPurify from 'dompurify';
import { IoAddSharp, IoArrowDown, IoAtCircle, IoCaretDown, IoCaretUp, IoCheckmarkCircle, IoChevronDown, IoChevronUp, IoCloudyNight, IoFemale, IoFlower, IoFootstepsOutline, IoFootstepsSharp, IoLeaf, IoLogoAppleAr, IoMale, IoMaleFemale, IoMan, IoMenu, IoPeople, IoPersonOutline, IoPersonSharp, IoRemoveSharp, IoSparkles, IoStar, IoStarHalf, IoStarOutline, IoStop, IoSunny, IoTimerOutline, IoTriangleSharp } from 'react-icons/io5';
import './Product.css';
import ImageViewer from "react-simple-image-viewer";
import { AiOutlineHome } from 'react-icons/ai';
import Marquee from 'react-fast-marquee';
import { TbHours24 } from 'react-icons/tb';
import Slider from 'react-slick';
import SecureLS from "secure-ls";
import SweetAlert2 from 'react-sweetalert2';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import ReactStars from 'react-rating-stars-component';
import StarRating from '../../Utils/StarRating';
import RelatedProducts from '../../components/Products/RelatedProducts';

const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    for (let i = 0; i < fullStars; i++) {
        stars.push(<IoStar key={`full-${i}`} color='gold' />);
    }

    if (halfStar) {
        stars.push(<IoStarHalf key="half" color='gold' />);
    }

    for (let i = 0; i < emptyStars; i++) {
        stars.push(<IoStarOutline key={`empty-${i}`} />);
    }

    return stars;
};

const ProductDetail = () => {

    const navigate = useNavigate();
    const ls = new SecureLS({ encodingType: 'aes' });

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    const { productName } = useParams();
    const product_name = productName.replace(/-/g, ' ');
    const baseURL = 'https://stylespotlightco.in/';
    const [item, setItem] = useState(null);
    const [mainImage, setMainImage] = useState('');
    const [selectedSize, setSelectedSize] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { addToCart } = useContext(CartContext);
    const { addToWishlist, removeFromWishlist } = useContext(WishlistContext);
    const [swalProps, setSwalProps] = useState({});
    const [testimonials, setTestimonials] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [formData, setFormData] = useState({
        comment: '',
        rating: '',
        product_images: []
    });

    useEffect(() => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        username ? setIsLoggedIn(true) : setIsLoggedIn(false);
        fetchProduct(sessionId, username, product_name);
    }, [product_name]);

    const fetchProduct = async (sessionId, username, product_name) => {
        try {
            const response = await axios.get(`${baseURL}/api/client_api/products/getproductdetails/index.php`, {
                params: {
                    name: product_name,
                    email: username || 'na',
                    ipadd: sessionId || 'na'
                }
            });
            const fetchedItem = response.data.data[0];
            setItem(fetchedItem);
            updateRecentlyViewed(fetchedItem);
            setMainImage(baseURL + fetchedItem.images[0]);
            setSelectedSize(fetchedItem.price[0].size); // Default size
            setTestimonials(fetchedItem.review_and_ratings ? fetchedItem.review_and_ratings : []);
            setIsLoading(false);
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
    };

    const updateRecentlyViewed = (product) => {
        const recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed')) || [];

        // Check if the product is already in the list
        const exists = recentlyViewed.find(item => item.id === product.id);

        if (!exists) {
            // Add the new product to the beginning of the array
            recentlyViewed.unshift(product);

            // Keep only the last 5 items (for example)
            if (recentlyViewed.length > 5) {
                recentlyViewed.pop();
            }

            // Save back to local storage
            localStorage.setItem('recentlyViewed', JSON.stringify(recentlyViewed));
        }
    };


    const handleSizeChange = (size) => {
        setSelectedSize(size)
    };
    const handleQuantityChange = (change) => setQuantity(Math.max(1, quantity + change));
    // const handleThumbnailClick = (image) => setMainImage(baseURL + image);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const handleThumbnailClick = (index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const handleAddToCart = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item?.price.find(p => p.size === selectedSize);

        const success = username
            ? await addToCart('na', username, { selectedProdCode: selectedPriceDetails.prodcode, quantity })
            : await addToCart(sessionId, 'na', { selectedProdCode: selectedPriceDetails.prodcode, quantity });

        if (success) {
            setSwalProps({
                show: true,
                title: item?.name,
                text: 'Added to Cart!',
                icon: 'success',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#ae8625 '
            });
        } else {
            console.error('Failed to add to cart');
            setSwalProps({
                show: true,
                title: item?.name,
                text: 'Failed to add to cart!',
                icon: 'error',
                confirmButtonText: 'Try again.',
                confirmButtonColor: '#ff0000'
            });
        }
    };

    const handleAddToWishlist = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item?.price.find(p => p.size === selectedSize);
        const success = username
            ? await addToWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await addToWishlist(sessionId || 'na', 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            console.log(`Added ${item?.name} to wishlist`);
        } else {
            console.error('Failed to add to wishlist');
        }
    };

    const handleRemoveFromWishlist = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item?.price.find(p => p.size === selectedSize);
        const success = username
            ? await removeFromWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await removeFromWishlist(sessionId || 'na', 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            console.log(`Removed ${item?.name} from wishlist`);
        } else {
            console.error('Failed to remove from wishlist');
        }
    };

    const sanitizeHTML = (html) => {
        return DOMPurify.sanitize(html);
    };


    const generateSlug = (name) => {
        return name.replace(/ /g, '-').replace(/[^\w-]+/g, '');
    };


    const Discount = ((item?.price.find(p => p.size === selectedSize)?.basePrice - item?.price.find(p => p.size === selectedSize)?.discountedPrice) / item?.price.find(p => p.size === selectedSize)?.basePrice) * 100;
    const DiscountNearestPercentage = Math.round(Discount);
    const settings = {
        customPaging: function (i) {
            // Return an image element with the thumbnail for the custom paging
            return (
                <a>
                    <img
                        src={item?.price.find(p => p.size === selectedSize)?.isPerfume ? (item?.perfumeimages[i] ? baseURL + item?.perfumeimages[i] : '') : (item?.images[i] ? baseURL + item?.images[i] : '')}
                        alt={`Thumbnail ${i}`}
                        className="slick-thumbnail me-2"
                    />
                </a>
            );
        },
        dots: true,
        // dotsClass: "slick-dots slick-thumb",
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const RatingsCard = ({ item }) => (
        <Card className='h-100 me-2 mb-2 bg-transparent border-black rounded-3' style={{ maxHeight: '18rem', minHeight: '10rem', }}>
            <Card.Body>
                <Card.Text className='text-start mb-1'>
                    {renderStars(item?.rating)}
                </Card.Text>
                <Card.Text className='text-start text-black mb-1'>{item?.name} <IoCheckmarkCircle color="green" />
                </Card.Text>
                <Card.Text className='text-start text-black mb-1'>{item?.comment}</Card.Text>
                {item?.product_images.map((img, index) => (
                    <img key={index} src={img} alt="Product" width="100" className="mr-2" />
                ))}
            </Card.Body>
        </Card>
    );

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 3) {
            alert("You can only upload up to 3 images");
            return;
        }

        const validFiles = files.filter(file => file.size <= 1024 * 1024); // 1MB limit
        if (validFiles.length !== files.length) {
            alert("Some images are larger than 1MB");
            return;
        }

        setFormData({
            ...formData,
            product_images: validFiles
        });
    };

    const handleRatingChange = (newRating) => {
        setFormData({ ...formData, rating: newRating });  // Only updates rating, doesn't re-render whole component
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const username = ls.get('userEmail');

        const updatedFormData = {
            ...formData,
            username: username,
            productId: item?.id
        };

        // console.log("Form submitted", updatedFormData);

        axios.post('https://stylespotlightco.in/api/client_api/products/rateProducts/', updatedFormData)
            .then(response => {
                // console.log(response.data);

                if (response.data.status == 'success') {
                    // Clear form data
                    setFormData({
                        // Reset form fields to their initial values
                        comment: '',
                        rating: '',
                        product_images: [] // Adjust this based on how you store images
                    });

                    const sessionId = sessionStorage.getItem('sessionId');
                    const username = ls.get('userEmail');
                    fetchProduct(sessionId, username, product_name);
                } else {
                    alert('Error submitting a review!')
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const Breadcrumb = ({ product }) => {
        // Extract collection name and product name
        const collections = JSON.parse(product);
        const excludedCollections = ["All Products", "Unisex", "Men", "Women", "For Her", "For Him"];
        const filteredCollections = collections.filter(collection => !excludedCollections.includes(collection));
        const collectionName = filteredCollections.length > 0 ? filteredCollections[0] : 'All Products';

        return (
            <div className='breadcrumb-container'>
                <div className='breadcrumb'>
                    <Link to='/' className='breadcrumb-item text-black me-2'>Style Spotlight Co.</Link>
                    <div className='breadcrumb-item text-black me-2'>Collections</div>
                    <Link to={`/collection/${collectionName}`} className='breadcrumb-item text-black me-2'>{collectionName}</Link>
                    <div className='breadcrumb-item text-black me-2'>{item?.name}</div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Container className="px-lg-5 px-md-5 px-sm-5 sectionView">
                {item?.collection &&
                    <Breadcrumb product={item?.collection} />
                }
                <Row className="my-4">
                    {/* for mobile */}
                    <div className="d-md-none">
                        <Slider {...settings}>
                            {
                                item?.images
                                    .map((image, index) => (
                                        <div key={index}>
                                            <Image
                                                src={baseURL + image}
                                                className='bg-transparent border-0 p-0 mb-2 mobile-product-image w-100'
                                                onClick={() => handleThumbnailClick(index)}
                                            />
                                        </div>
                                    ))
                            }
                        </Slider>
                    </div>
                    {/* for Desktop */}
                    <Col md={2} className="d-none d-md-block">
                        <Row>
                            {
                                item?.images
                                    .map((image, index) => (
                                        <Col xs={12} key={index}>
                                            <Image
                                                src={baseURL + image}
                                                thumbnail
                                                className='bg-transparent border-0 p-0 mb-2 w-100'
                                                onClick={() => setMainImage(baseURL + image)}
                                            />
                                        </Col>
                                    ))
                            }
                        </Row>
                    </Col>
                    <Col md={5} className="d-none d-md-block">
                        <Image src={mainImage} className='rounded-3 w-100' style={{ position: 'sticky', top: '10vh' }}
                            onClick={() => handleThumbnailClick(0)}
                        />
                    </Col>
                    {isViewerOpen && (
                        <ImageViewer
                            src={item?.price.find(p => p.size === selectedSize)?.isPerfume ? item?.perfumeimages.map(image => baseURL + image) : item?.images.map(image => baseURL + image)} currentIndex={currentImage}
                            onClose={closeImageViewer}
                            disableScroll={false}
                            backgroundStyle={{
                                backgroundColor: "rgba(0,0,0,0.9)"
                            }}
                            closeOnClickOutside={true}
                        />
                    )}

                    <Col md={5}>
                        <div style={{ position: 'sticky', top: '10vh' }}>
                            <span className="fs-6 gradient-text">
                                Style Spotlight Co.
                            </span>
                            <h2 className='gradient-text'>{item?.name}</h2>
                            <span className={`text-${item?.price.find(p => p.size === selectedSize)?.inStock ? 'success' : 'danger'} mx-2`}>
                                {item?.price.find(p => p.size === selectedSize)?.inStock ? 'In stock' : 'Out of stock'}
                            </span>
                            {item?.price.find(p => p.size === selectedSize)?.average_rating > 0 &&
                                <div className='d-flex'>
                                    <StarRating rating={String(item?.price.find(p => p.size === selectedSize)?.average_rating)} />
                                    <div className="rating text-black  fw-bold fs-6 ms-2">{String(item?.price.find(p => p.size === selectedSize)?.average_rating)}/5</div>
                                </div>
                            }
                            <span className="text-muted text-decoration-line-through">Rs {item?.price.find(p => p.size === selectedSize)?.basePrice || item?.price[0].basePrice}</span>
                            <h4 className='text-black pt-2'>Rs {item?.price.find(p => p.size === selectedSize)?.discountedPrice || item?.price[0].discountedPrice}
                                <span className="badge abstext-danger text-danger ms-2 rounded-5" style={{ background: '#ff333352' }}>{DiscountNearestPercentage}%</span>
                            </h4>
                            <h5 className='text-black mb-3'>Choose Size <span className='text-muted mb-3 pt-0' style={{ fontSize: '14px' }}>(Check size chart in images for reference)</span></h5>
                            <ButtonToolbar className="mb-4">
                                {item?.price.map((p, index) => (
                                    <Button
                                        key={index}
                                        variant={selectedSize === p.size ? 'dark' : 'outline-dark'}
                                        onClick={() => handleSizeChange(p.size)}
                                        className="rounded-0 px-3 me-1"
                                    >
                                        {p.size}
                                    </Button>
                                ))}
                            </ButtonToolbar>
                            <div className="quantity-selector mb-3 d-flex">
                                <ButtonGroup className="me-3">
                                    <Button variant='outline-dark' className='text-black border-1 rounded-0' onClick={() => handleQuantityChange(-1)}>
                                        <IoRemoveSharp />
                                    </Button>
                                    <Button disabled variant='outline-dark' className="align-self-center text-black bg-light px-4 py-2 fw-bold">{quantity}</Button>
                                    <Button variant='outline-dark' className='text-black border-1  rounded-0' onClick={() => handleQuantityChange(1)}>
                                        <IoAddSharp />
                                    </Button>
                                </ButtonGroup>
                                <Button block="true" disabled={item?.price.find(p => p.size === selectedSize)?.inStock ? false : true} variant="dark" className='rounded-0 w-75' onClick={handleAddToCart}>Add to Cart</Button>
                            </div>
                            <Link to={item?.price.find(p => p.size === selectedSize)?.inStock ? '/checkout' : ''} state={{ item: item, isBuyNow: 'yes', isCart: 'no', selectedSize: selectedSize }} >
                                <Button block="true" disabled={item?.price.find(p => p.size === selectedSize)?.inStock ? false : true} variant='outline-dark' className='rounded-0 w-100'>Buy Now</Button>
                            </Link>

                            <Tabs defaultActiveKey="details" id="product-tabs" className="mb-3 mt-5 d-flex justify-content-around align-items-center custom-tabs" variant="underline" >
                                <Tab eventKey="details" title="Product Details" >
                                    <p className='text-black' dangerouslySetInnerHTML={{ __html: sanitizeHTML(item?.description) }} ></p>
                                </Tab>
                                <Tab eventKey="reviews" title="Rating & Reviews" >
                                    {/* FAQs content */}
                                </Tab>
                                <Tab eventKey="faqs" title="FAQs">
                                    {/* FAQs content */}
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Container>
            {item?.collection &&
                <RelatedProducts productCollection={item?.collection} />
            }
            <RecentlyAdded />
            <SweetAlert2
                didClose={() => {
                    setSwalProps({
                        show: false,
                    });
                }}
                {...swalProps}
            />
        </>
    );
};

export default ProductDetail;
