import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import './Footer.css';
import razorpaypng from '../../assets/images/razorpay.png'
import Whatsapp from '../Floaters/Whatsapp';
import Callus from '../Floaters/Callus';
import VideoPopup from '../Floaters/VideoPopup';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Footer = () => {
    const [collections, setCollections] = useState([]);


    useEffect(() => {
        getCollections();
    }, []);

    const getCollections = async () => {
        try {
            const response = await axios.get('https://stylespotlightco.in/api/client_api/Gender/index.php');
            setCollections(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <footer className="footer text-secondary py-5">
            <Container>
                <Row className="mb-4">
                    <Col xs={12} md={6} lg={4}>
                        <h5 className="mb-3 fw-bold gradient-text">Syle Spotlight Co.</h5>
                        <p className='text-secondary' >"At Style Spotlight Co, we believe that fashion is more than just clothing; it's a statement of individuality and expression. Our brand is dedicated to offering trendy and affordable clothes and printed T-shirts that allow you to showcase your unique style and personality. Join us in celebrating fashion, creativity, and self-expression. Shop with us and discover your style spotlight today!"</p>
                        <div className="social-icons mb-4">
                            <a className='text-secondary' href="https://www.facebook.com/theattarco/" target='_blank'><FaFacebookF className="me-3" /></a>
                            {/* <a className='text-secondary' href="https://www.instagram.com/theattarco/" target='_blank'><FaTwitter className="me-3"/></a> */}
                            <a className='text-secondary' href="https://www.instagram.com/theattarco/" target='_blank'><FaInstagram className="me-3" /></a>
                            <a className='text-secondary' href="https://wa.me/918129616717?text=Hello,I%20have%20a%20question%20about%20https://stylespotlightco.in/" target='_blank'><FaWhatsapp className="me-3" /></a>
                        </div>
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <h6 className="mb-3  gradient-text">COMPANY</h6>
                        <ul className="list-unstyled">
                            <li><a className='text-secondary' href="/">Home</a></li>
                            {collections
                                .filter(item => item?.name !== "All Products") // Exclude "All Products"
                                .map((item, index) => (
                                    <li key={index}><a className='text-secondary' href={`/collection/${encodeURIComponent(item?.name)}`}>{item?.name}</a></li>
                                ))
                            }

                            {/* <li><a className='text-secondary' href="/aboutus">About Us</a></li> */}
                            <li><a className='text-secondary' href="/track-order">Orders Tracking</a></li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <h6 className="mb-3  gradient-text">HELP</h6>
                        <ul className="list-unstyled">
                            <li><a className='text-secondary' href="/contactus">Support</a></li>
                            <li><a className='text-secondary' href="/termsandcondtions">Terms & Conditions</a></li>
                            <li><a className='text-secondary' href="/privacypolicy">Privacy Policy</a></li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <h6 className="mb-3  gradient-text">POLICIES</h6>
                        <ul className="list-unstyled">
                            <li><a className='text-secondary' href="/exchangereturnpolicy">Return & Exchange Policy</a></li>
                            <li><a className='text-secondary' href="/cookiepolicy">Cookie Policy</a></li>
                            <li><a className='text-secondary' href="/accessibilitypolicy">Accessibility Policy</a></li>
                            <li><a className='text-secondary' href="/shippingpolicy">Shipping Policy</a></li>
                            <li><a className='text-secondary' href="/communityguidelines">Community Guidelines</a></li>
                            <li><a className='text-secondary' href="/copyrightnotice">Copyright Notice</a></li>
                            <li><a className='text-secondary' href="/disclaimer">Disclaimer</a></li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <h6 className="mb-3  gradient-text">CONTACT US</h6>
                        <ul className="list-unstyled">
                            <li><a className='text-secondary' href="tel:+917975212565">797 521 2565</a></li>
                            <li><a className='text-secondary' href="mailto:hi@stylespotlightco.in">hi@stylespotlightco.in</a></li>
                        </ul>
                    </Col>
                </Row>
                <Row className="justify-content-between align-items-center">
                    <Col md={6}>
                        <p className="mb-0 text-center text-lg-start fs-6  gradient-text">stylespotlightco.in © 2024, All Rights Reserved</p>
                    </Col>
                    <Col md={3} className="text-end">
                        <img src={razorpaypng} alt="Visa" className="me-2 w-100" />
                    </Col>
                </Row>
                <Row className="justify-content-center align-items-center">
                    <Col md={4}>
                        <p className="mb-0 text-center text-lg-start fs-6 gradient-text">powered by <a href='https://w3builders.in' target="_blank">w3builders Technology Solutions LLP </a></p>
                    </Col>
                </Row>
            </Container>
            <Whatsapp />
            <Callus />
        </footer>
    );
}

export default Footer;
