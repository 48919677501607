import React, { useState, useEffect, useContext } from 'react';
import { Container, Navbar, Nav, Form, FormControl, InputGroup, Dropdown, DropdownButton, Button, Badge, Row, Col, Modal } from 'react-bootstrap';
import './Header.css';
import { IoBagHandleOutline, IoCalendarClearOutline, IoCallOutline, IoCartOutline, IoHeartOutline, IoMenuOutline, IoMenuSharp, IoPersonOutline, IoSearchOutline } from "react-icons/io5";
import logoImageblack from '../../assets/images/brandblack.png';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaCalendar, FaFacebook, FaFacebookF, FaInstagram, FaTimes, FaWhatsapp } from 'react-icons/fa';
import { TbBrandGoogleMaps } from 'react-icons/tb';
import { AiFillTruck, AiFillYoutube, AiOutlineMail, AiOutlineTruck, AiOutlineWhatsApp } from 'react-icons/ai';
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import SecureLS from "secure-ls";

function Header() {
    const { cartCount, cartTotal } = useContext(CartContext);
    const { wishlistCount } = useContext(WishlistContext); // Use wishlist context
    const [iconColor, setIconColor] = useState('white');
    const [searchInput, setSearchInput] = useState('');
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [headerBackground, setHeaderBackground] = useState(false); // Track header background color
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchInput != '') {
            navigate(`/search/${searchInput}`, {
                state: {
                    searchInput
                }
            });
        }
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        // Close the sidebar first
        handleSidebarClose();

        // After closing the sidebar, show the logout confirmation modal
        setTimeout(() => {
            setShow(true);
        }, 300); // Adjust the delay if necessary
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.pageYOffset;

            // Change header background when scrolled down
            if (currentScrollTop > 50) {
                setHeaderBackground(true); // Scrolled down, change background to white
            } else {
                setHeaderBackground(false); // At the top, remove white background
            }

            setLastScrollTop(currentScrollTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);


    const [categories, setCategories] = useState([])
    const baseUrl = 'https://stylespotlightco.in/'

    useEffect(() => {
        getCategories();
    }, [])

    const getCategories = async () => {
        try {
            const response = await axios.get('https://stylespotlightco.in/api/client_api/productCategories/index.php');
            setCategories(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    // Initialize SecureLS
    const ls = new SecureLS({ encodingType: 'aes' });

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState('');
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        // ls.removeAll();
        // Retrieve the stored email and name using SecureLS
        const email = ls.get('userEmail');
        const name = ls.get('userName');

        if (email && name) {
            setIsLoggedIn(true);
            setUserName(name);
        }

        getCollections();

    }, []);

    const SignInLink = () => {
        const location = useLocation();

        const handleSignInClick = () => {
            // Store the current path in sessionStorage
            sessionStorage.setItem('redirectPath', location.pathname);
        };

        return (
            <Link
                to='/auth/login'
                className='text-decoration-none text-white d-flex align-items-center justify-content-center'
                onClick={handleSignInClick} // Call this function on click
            >
                <div className="icon-text-container">
                    <IoPersonOutline size={'22px'} color='#000' className="icon" />
                </div>
            </Link>
        );
    };


    const UserDropdown = () => {
        return (
            <Link
                to='/account'
                className='text-decoration-none text-white d-flex align-items-center justify-content-center'
            >
                <div className="icon-text-container">
                    <IoPersonOutline size={'22px'} color='#000' className="icon" />
                </div>
            </Link>
        );
    };

    const handleMenuClick = () => {
        setSidebarOpen(true);
    };

    const handleSidebarClose = () => {
        setSidebarOpen(false);
    };

    const handleLogout = async () => {
        try {
            // Send the POST request to the logout endpoint
            const response = await axios.post('https://stylespotlightco.in/api/client_api/authentication/logout/index.php');

            // Check if the response indicates success
            if (response.data.success) {
                // Remove user data from SecureLS
                ls.remove('userEmail');
                ls.remove('userName');

                // Update state to reflect logout
                setIsLoggedIn(false);
                setUserName('');

                // Redirect to home page
                window.location.href = 'https://stylespotlightco.in';
            } else {
                // Handle the case where the server response is not successful
                alert('Error logging out, please try again');
            }
        } catch (error) {
            // Handle any errors during the request
            console.error('Error logging out:', error);
            alert('Error logging out: Please try again.');
        }
    };


    const getCollections = async () => {
        try {
            const response = await axios.get('https://stylespotlightco.in/api/client_api/Gender/index.php');
            setCollections(response.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    };

    return (
        <div>
            <header className={`custom-header ${headerBackground ? 'header-scrolled' : ''}`}>
                <div className='top-header px-lg-5 px-md-5 px-sm-5'>
                    <Container className="d-flex align-items-center justify-content-between">
                        <div className="logo" style={{ flexBasis: '10%' }}>
                            <Navbar.Brand className='d-flex justify-content-center align-items-center'>
                                <Link to="/">
                                    <img
                                        src={logoImageblack}
                                        width="auto"
                                        height="90"
                                        className="d-inline-block align-top"
                                        alt="stylespotlightco"
                                    />
                                </Link>
                            </Navbar.Brand>
                        </div>
                        <div className='d-none justify-content-center align-items-center d-lg-flex' style={{ flexBasis: '70%' }}>
                            <Navbar id="basic-navbar-nav">
                                <Nav className="me-auto text-black">
                                    <Nav.Link as={Link} to="" className="text-black fw-bold me-2 bottomNavbarCategories">Home</Nav.Link>
                                    {collections
                                        .filter(item => item?.name !== "All Products") // Exclude "All Products"
                                        .slice(0, 4) // Get the first three items
                                        .map((item, index) => (
                                            <Nav.Link
                                                as={Link}
                                                key={index}
                                                to={`collection/${encodeURIComponent(item?.name)}`} // Encode the name for the URL
                                                className="text-black fw-bold me-2 bottomNavbarCategories"
                                            >
                                                {item?.name}
                                            </Nav.Link>
                                        ))}
                                    {collections.length > 5 && ( // Check if there are more than 4 items total
                                        <Nav.Link
                                            as={Link}
                                            to="collection/All Products" // Link to all products
                                            className="text-black fw-bold me-2 bottomNavbarCategories"
                                        >
                                            More
                                        </Nav.Link>
                                    )}

                                    <Nav.Link as={Link} to="track-order" className="text-black fw-bold me-2 bottomNavbarCategories">Track Order</Nav.Link>
                                </Nav>
                            </Navbar>
                        </div>
                        <div className="d-flex align-items-center justify-content-end" style={{ flexBasis: '20%' }}>
                            <div className='d-flex'>
                                <Link to='search' className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoSearchOutline size={'22px'} color='#000' className="icon" />
                                    </div>
                                </Link>
                                {isLoggedIn ? <UserDropdown /> : <SignInLink />}
                                {/* <Link to='Wishlist' className='text-decoration-none d-none d-lg-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoHeartOutline size={'22px'} color='#000' className="icon" />
                                        <span className='icon-badge wishlist-badge fw-bold'> {wishlistCount} </span>
                                    </div>
                                </Link> */}
                                <Link to='Cart' className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoBagHandleOutline size={'22px'} color='#000' className="icon" />
                                        <span className='icon-badge cart-badge fw-bold'> {cartCount} </span>
                                    </div>
                                </Link>
                                <div className='text-decoration-none d-flex d-lg-none align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoMenuSharp size={'22px'} color='#000' className="icon"
                                            onClick={() => handleMenuClick()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </header>

            {/* Backdrop */}
            {(isSidebarOpen) && (
                <div className="backdrop show" onClick={handleSidebarClose}></div>
            )}

            {/* Menu Sidebar */}
            <aside className={`wrap-sidebar js-sideorderbar ${isSidebarOpen ? 'show-sidebar' : ''}`}>
                <div className="sidebar p-t-22 p-b-25">
                    <div className="d-flex justify-content-between align-items-center " style={{ position: 'sticky', top: 0, zIndex: 1, }}>
                        <div className="text-dark mobile-menu-toggle-icon f12 fw-bold">
                            <div className="logo" style={{ flexBasis: '10%' }}>
                                <Navbar.Brand className='d-flex justify-content-center align-items-center'>
                                    <Link to="/">
                                        <img
                                            src={logoImageblack}
                                            width="auto"
                                            height="50"
                                            className="d-inline-block align-top"
                                            alt="stylespotlightco"
                                        />
                                    </Link>
                                </Navbar.Brand>
                            </div>
                        </div>
                        <div className="mobile-menu-toggle-icon js-hide-sideordertrackbar" onClick={handleSidebarClose}>
                            <FaTimes size={'22px'} color='#000' className="icon" />
                        </div>
                    </div>
                    <div className="dropdown px-3">
                        <div className="dropdown-options py-2">
                            <Link to="/">
                                Home
                            </Link>
                        </div>
                        {collections
                            .filter(item => item?.name !== "All Products") // Exclude "All Products"
                            .slice(0, 4) // Get the first three items
                            .map((item, index) => (
                                <div className="dropdown-options py-2" key={index}>
                                    <Link to={`collection/${encodeURIComponent(item?.name)}`} >
                                        {item?.name}
                                    </Link>
                                </div>
                            ))}
                        {collections.length > 5 && ( // Check if there are more than 4 items total
                            <div className="dropdown-options py-2">
                                <Link to="collection/All Products" >
                                    More
                                </Link>
                            </div>
                        )}
                        <div className="dropdown-options py-2">
                            <Link to="contactus">
                                Contact Us
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="track-order">
                                Track Order
                            </Link>
                        </div>
                    </div>
                </div>
            </aside>

            {/* Logout Confirmation Modal */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title>Confirm Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to log out?</Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="dark" onClick={handleLogout}>
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Header;
