import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import './Contactus.css';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';

const Contactus = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToTop();
  }, [navigate]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.phone) newErrors.phone = 'Phone is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        // Sending data as a POST request with formData included
        const response = await axios.post('https://stylespotlightco.in/api/client_api/contactus/index.php', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded', // Ensure correct content type for form data
          },
        });

        if (response.data.status) {
          // Reset form data on successful submission
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
          });
          setSubmitted(true);
        } else {
          setSubmitted(false); // Indicate failure
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setSubmitted(false); // Indicate failure
      }
    }
  };




  return (
    <div className='sectionView mt-3'>
      <section className="p-b-10">
        <Container fluid className="px-lg-5 px-md-5 px-sm-5">
          <div className='breadcrumb-container'>
            <div className='breadcrumb'>
              <a href='/' className='breadcrumb-item text-white'>Home</a>
              <div className='breadcrumb-item text-white'>Contact Us</div>
            </div>
          </div>
          <section className="bg-img1 text-center fw-bold p-lr-15 p-tb-92">
            <h2 className="ltext-103 text-center text-white cl4">
              Contact Us
            </h2>
          </section>
          <Row className="mt-4">
            {/* Left Column: Contact Form */}
            <Col md={6}>
              <div className="border rounded-2 p-4 bg-light mb-4" style={{ position: 'sticky', top: '10vh' }}>
                <h4 className="text-center text-black mb-3">Send us an e-Mail</h4>
                {submitted && <Alert variant="success">Your message has been sent successfully!</Alert>}
                <Form onSubmit={handleSubmit} className="form-submit">
                  <Form.Floating className="mb-3">
                    <Form.Control
                      required
                      className='rounded-pill'
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter your name"
                      value={formData.name}
                      onChange={handleInputChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Label htmlFor="name">Name</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Floating>

                  <Form.Floating className="mb-3">
                    <Form.Control
                      required
                      className='rounded-pill'
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={handleInputChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Label htmlFor="email">Email address</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Floating>

                  <Form.Floating className="mb-3">
                    <Form.Control
                      required
                      className='rounded-pill'
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="Enter your phone number"
                      value={formData.phone}
                      onChange={handleInputChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Label htmlFor="phone">Phone</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Floating>

                  <Form.Floating className="mb-3">
                    <Form.Control
                      as="textarea"
                      required
                      className='rounded-4'
                      id="message"
                      name="message"
                      placeholder="Enter your message"
                      value={formData.message}
                      onChange={handleInputChange}
                      style={{ height: '170px' }}
                      isInvalid={!!errors.message}
                    />
                    <Form.Label htmlFor="message">Compose</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  </Form.Floating>

                  <Button type="submit" className="btn btn-dark py-3 w-100 rounded-pill">
                    Send
                  </Button>
                </Form>
              </div>
            </Col>

            {/* Right Column: Map on Top, Address Below */}
            <Col md={6}>
              <div className="border rounded-3 bg-light mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3921.282890899081!2d75.98626847503984!3d10.6351172895052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7977f940759f3%3A0x73bed75c7583d398!2sThe%20Attar%20Co.!5e0!3m2!1sen!2sin!4v1723542608697!5m2!1sen!2sin"
                  width="100%"
                  height="250"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  className='rounded-3'
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="border p-3 rounded-3 bg-light">
                <div className="d-flex mb-3">
                  <span className="fs-18 text-center mr-2">
                    <i className="lnr lnr-map-marker text-dark"></i>
                  </span>
                  <div>
                    <h6 className="m-0 text-dark">Address</h6>
                    <p className="m-0 text-dark">
                      <span className="fw-bold">Style Spotlight Co.</span><br />
                      VP CENTRE AKALAD, Chavakkad, Kerala 680518
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <span className="fs-18 text-center mr-2">
                    <i className="lnr lnr-phone-handset text-dark"></i>
                  </span>
                  <div>
                    <h6 className="m-0 text-dark">Let's Talk</h6>
                    <p className="m-0 text-dark">
                      <a href="tel:+918129616717" className="text-black" target="_blank">
                        +91 81296 16717
                      </a>
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <span className="fs-18 text-center mr-2">
                    <i className="lnr lnr-bubble text-dark"></i>
                  </span>
                  <div>
                    <h6 className="m-0 text-dark">WhatsApp Us on</h6>
                    <p className="m-0 text-dark">
                      <a className='text-decoration text-black' href="https://wa.me/918129616717?text=Hello,I have a question about https://stylespotlightco.in/" target="_blank" rel="noopener noreferrer">
                        +91 81296 16717
                      </a>
                    </p>
                  </div>
                </div>
                <div className="d-flex">
                  <span className="fs-18 text-center mr-2">
                    <i className="lnr lnr-envelope text-dark"></i>
                  </span>
                  <div>
                    <h6 className="m-0 text-dark">General Support</h6>
                    <p className="m-0 text-dark">info@stylespotlightco.in</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Contactus;
